import React from 'react'

export default class SquareAd extends React.Component {
  componentDidMount () {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

render () {
    return (
      <div className='ad'>
        <ins className="adsbygoogle"
            style={{display:"block"}}
            data-ad-client="ca-pub-7166821459537676"
            data-ad-slot="8112215733"
            data-ad-format="auto"
            data-full-width-responsive="true" />
      </div>
    );
  }
}