import React from 'react'

export default class FeedAd extends React.Component {
  componentDidMount () {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

render () {
    return (
      <div className='ad'>
        <ins className="adsbygoogle"
            style={{display:"block"}}
            data-ad-format="fluid"
            data-ad-layout-key="-cd-3f+7p+mg-op"
            data-ad-client="ca-pub-7166821459537676"
            data-ad-slot="9044371063" />
      </div>
    );
  }
}