import * as React from "react";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import AdUnit from "../components/adUnits/inFeedAd"

const FeatureGrid = ({ gridItems }) => (
  <div className="columns is-multiline">
    {gridItems.map((item, index) => 
      <div className="column is-12">
        { index !== 0 && index%3===0 &&
          <AdUnit />
        }
        <div key={item.text}>
          <section className="section card">
            <div className="columns is-multiline">
              <div className="column is-4">
                <div className="downloadable-image">
                  <PreviewCompatibleImage imageInfo={item} />
                </div>
              </div>
              <div className="column">
                <p>{item.text}</p>
                <a className="button is-card-cta is-size-5-tablet is-size-6-touch" href={item.gumroadurl} target="_blank" rel="nofollow">
                  Get The Code
                </a>
              </div>
            </div>
          </section>
        </div>
      </div>
    )}
  </div>
);

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
      gumroadurl: PropTypes.string
    })
  ),
};

export default FeatureGrid;
