import * as React from "react";
import PropTypes from "prop-types";

export default function TestimonialCard(props) {
    const {
        id,
        avatar,
        name,
        message,
        youtubeLink,
        videoTitle
    } = props;
  
    return (
      <React.Fragment>
        <div className="testimonial-slide has-text-left">
            <div className="columns is-multiline">
                <div key={id} className="card column is-12">
                    <div className="card-content">
                        <div className="media">
                            <div className="media-left">
                                <figure className="image is-48x48">
                                    <img 
                                        id="avatarImage"
                                        src={avatar}
                                        alt="User Avatar"
                                        style={{
                                            clipPath: "circle(50% at 50% 50%)"
                                        }}
                                    />
                                </figure>
                            </div>
                            <div className="media-content">
                                <p className="is-4" style={{color: "#156AE3"}}>
                                    {name}
                                </p>
                                <p className="is-6" style={{paddingTop: "12px"}}>
                                    {message}
                                </p>
                            </div>
                        </div>
                        <hr />
                        <div className="content" style={{paddingTop: "20px", display:"block"}}>
                            <b>The Guide they used:&nbsp;</b>
                            <a 
                                href={youtubeLink} 
                                target="_blank"
                                onClick={(index) => { window.open({youtubeLink}) }}
                            >
                                {videoTitle}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </React.Fragment>
    );
  }
  
  TestimonialCard.propTypes = {
    avatar: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    name: PropTypes.string,
    message: PropTypes.string,
    youtubeLink: PropTypes.string,
    videoTitle: PropTypes.string,
  };