import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from '../PreviewCompatibleImage'
import SquareAd from '../../components/adUnits/squareAd'

class GuideRollTemplate extends React.Component {
  render() {
    const { data: posts } = this.props
    const { page } = this.props

    return (
      <div className="columns is-multiline">
        {posts &&
          posts.map(({ node: post }) => (
            <div className="is-parent column is-4" key={post.id}>
              <article
                className={`video-list-item tile is-child box ${
                  post.frontmatter.featuredpost ? 'is-featured' : ''
                }`}
              >
                <header>
                  {post.frontmatter.featuredimage ? (
                    <Link
                    className="title is-size-4"
                    to={post.fields.slug}
                    >
                      <div className="featured-thumbnail">
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: post.frontmatter.featuredimage,
                            alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                            width:
                              post.frontmatter.featuredimage.childImageSharp
                                .gatsbyImageData.width,
                            height:
                              post.frontmatter.featuredimage.childImageSharp
                                .gatsbyImageData.height,
                          }}
                        />
                      </div>
                    </Link>
                  ) : null}
                </header>
                <Link
                  className="card-title is-size-4"
                  to={post.fields.slug}
                >
                  <p className="post-meta">
                    {post.frontmatter.title}
                  </p>
                </Link>
                <span className="is-size-6 is-italic is-block">
                  {post.frontmatter.date}
                </span>
              </article>
            </div>
          ))}
          { page !== 'index-page' && 
            <div className="is-parent column is-4">
              <SquareAd />
            </div>
          }
      </div>
    )
  }
}

const GuideRoll = ({ data, page }) => {
  return (
    <GuideRollTemplate data={data} page={page} />
  )
}

export default GuideRoll