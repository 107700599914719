import React from "react";
import { Carousel } from "react-responsive-carousel"
import TestimonialCard from "./TestimonialCard"
import avatar1 from "../img/testimonials/avatar1.jpeg"
import avatar2 from "../img/testimonials/avatar2.jpeg"
import avatar3 from "../img/testimonials/avatar3.jpeg"
import avatar4 from "../img/testimonials/avatar4.jpeg"
import avatar5 from "../img/testimonials/avatar5.jpeg"
import avatar6 from "../img/testimonials/avatar6.jpeg"
import avatar7 from "../img/testimonials/avatar7.jpeg"

export default function TestimonialCarousel() {
    const testimonials = [
        {
            avatar: avatar1,
            name: "Santiago Vini Garcia",
            message: "Thank you!! This code works perfect, subscribed 👍",
            youtubeLink: "https://www.youtube.com/watch?v=Y0BxVNR1HMA&lc=UgzGHi65wCJUjIKkY_V4AaABAg",
            videoTitle: "Get Your Airtable into a Google Data Studio Report in under 30 minutes",
        },
        {
            avatar: avatar2,
            name: "Hoàng Lâm",
            message: "Thanks for your tutorials, so easy to understand :D",
            youtubeLink: "https://www.youtube.com/watch?v=NBRnfkF46bs&lc=UgyWcQ0B_ZCzMsbV0Sp4AaABAg",
            videoTitle: "A Quick Guide to Using Custom Queries in Google Data Studio",
        },
        {
            avatar: avatar3,
            name: "Organo Interno Capacitacion",
            message: "I love your videos. They're entertaining and very very well explained.",
            youtubeLink: "https://www.youtube.com/watch?v=r17xt16_58k&lc=UgwMGPEMOO_VdJujxU54AaABAg",
            videoTitle: "AUTOMATE Your Google Search Console Data Pulls",
        },
        {
            avatar: avatar4,
            name: "VA Scheduler",
            message: "This is a great video! Thank you so much! You saved me from the hassle. :)",
            youtubeLink: "https://www.youtube.com/watch?v=v_DCrKkzRSg&lc=UgxUT5UYRM8T95p8UTt4AaABAg",
            videoTitle: "How to Automate Emails Through your Google Sheet",
        },
        {
            avatar: avatar5,
            name: "TrueMasterz",
            message: `This is exaaaaaactly what i have been searching for for multiple days now, great upload!`,
            youtubeLink: "https://www.youtube.com/watch?v=Gm4zbFQFeT0&lc=UgwB8Z5rvOGBTVAMIBZ4AaABAg",
            videoTitle: "Routing Emails Based on the User's Input on Google Forms",
        },
        {
            avatar: avatar6,
            name: "James Gasaway",
            message: "Thank you! It works great!",
            youtubeLink: "https://www.youtube.com/watch?v=9G_tObul-Io&lc=UgwDQRI4tzpadgrRZ_l4AaABAg",
            videoTitle: "How to add Hidden Fields to your Google Form",
        },
        {
            avatar: avatar7,
            name: "Inner peace Motivation",
            message: "Nice love you. I have just started watcing your tutorial. I liked and learned a lot.",
            youtubeLink: "https://www.youtube.com/watch?v=Gm4zbFQFeT0&lc=UgwTNu64uWCKeJSCmW94AaABAg",
            videoTitle: "Routing Emails Based on the User's Input on Google Forms",
        },
    ]
    return (
        <Carousel 
            autoPlay={true}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            useKeyboardArrows={true}
            transitionTime="800"
            interval="3000"
            className="has-text-centered"
            onClickItem={(index) => { window.open(testimonials[index].youtubeLink) }}
            width="100%"
        >
            {testimonials.map((item) => 
                <TestimonialCard 
                    key={item.avatar}
                    avatar={item.avatar}
                    name={item.name}
                    message={item.message}
                    youtubeLink={item.youtubeLink}
                    videoTitle={item.videoTitle}
                />
            )}
        </Carousel>
    )
}
