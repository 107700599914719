import React from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import Carousel from './TestimonialsCarousel'
import headerbg from "../img/banners/headerbg.png"

export default function TestimonialSection() {
  return (
    <React.Fragment>
      <div
        className="margin-top-0 has-text-white is-info"
        style={{
          // clipPath: "polygon(50% 25%, 100% 50%, 50% 100%, 0% 50%)",
          backgroundColor: "#122466",
          backgroundImage: `url(${headerbg})`,
          backgroundPosition: `35% 0%`,
          backgroundRepeat: 'no-repeat',
          height: "600px",
          color: "#fff"
        }}
      >
        <div className="container">
          <div className="columns is-multiline is-centered">
            <div className="column is-full">
              <section className="hero">
                <div className="hero-body">
                  <p className="section-header title is-size-2-tablet is-size-3-touch has-text-centered">
                    People we've helped
                  </p>
                </div>
              </section>
              <div className="columns is-multiline is-centered">
                <div className="column" />
                <div className="column is-two-thirds">
                  <Carousel />
                </div>
                <div className="column" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}