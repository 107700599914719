import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import Features from "../components/Features"
import GuideRoll from "../components/cards/GuideRoll"
import FullWidthImageHeader from "../components/LandingPageHeader"
import TestimonialSection from "../components/TestimonialSection"
import FaqSeciton from "../components/FAQSection"
import headerbg from "../img/banners/headerbg.png"
import Fade from 'react-reveal/Fade';

import infoGraphic1 from "../img/infoGraphics/infoGraphic1.webp"
import infoGraphic2 from "../img/infoGraphics/infoGraphic2.webp"
import infoGraphic2a from "../img/infoGraphics/infoGraphic2-a.png"
import infoGraphic2b from "../img/infoGraphics/infoGraphic2-b.png"
import infoGraphic2c from "../img/infoGraphics/infoGraphic2-c.png"

// eslint-disable-next-line
export const IndexPageTemplate = ({
  image,
  title,
  subheading,
  templateKey,
  data
}) => {
  const heroImage = getImage(image) || image;
  const [deskGraphic, setDeskGraphic] = React.useState(infoGraphic2);
  
  return (
    <div>
      <FullWidthImageHeader img={heroImage} title={title} subheading={subheading} />
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns is-multiline">
              <div className="column is-12">
                <div className="content">
                  <h3 className="has-text-centered has-text-weight-semibold is-size-1-tablet is-size-2-touch" style={{margin: "0 0 -10% 50%", float: "right"}}>
                    Code Faster with Snippets and Templates
                  </h3>
                  <div className="columns">
                    <div className="column">
                      <figure 
                        className="image"
                        style={{
                          marginBottom: "-150px",
                          zIndex: "-99"
                        }}
                      >
                        <img src={infoGraphic1} />
                      </figure>
                    </div>
                    <div className="column is-6">
                      <p className="is-size-4-tablet is-size-5-touch" style={{marginTop: "30%"}}>
                        With our collection of code snippets, templates, and generation tools - you’ll have everything you need build scripts faster than ever.
                      </p>
                      <Link className="button is-cta is-cta-secondary" to="/code-snippets">
                        Explore Code Snippets
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <TestimonialSection />
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns is-multiline">
              <div className="column is-12">
                <div className="content">
                  <h3 className="section-header has-text-centered has-text-weight-semibold is-size-1-tablet is-size-2-touch">
                    Become a Scripting Champ
                  </h3>
                  <div className="columns">
                    <div className="column is-7">
                      <ul className="is-size-4-tablet is-size-5-touch">
                        <li className="feature-bullets">
                          <Fade left duration={1200}>
                            <p 
                              onMouseEnter={(e) => setDeskGraphic(infoGraphic2b)}
                              onMouseOut={(e) => setDeskGraphic(infoGraphic2)}
                            >
                            Write scripts to <u><i>automate</i></u> repetitive work and focus on the bigger picture.
                            </p>
                          </Fade>
                        </li>
                        <li className="feature-bullets">
                          <Fade left fraction={1}>
                            <p 
                              onMouseEnter={(e) => setDeskGraphic(infoGraphic2a)}
                              onMouseOut={(e) => setDeskGraphic(infoGraphic2)}
                            >
                              Save the trip to Google with 50+ snippets right at your fingertips with the <b><a href="https://chrome.google.com/webstore/detail/bootstrapping-tools-nitro/najbgpipgacnhjcaabbhdaddlmebmopb" target="_blank">NitroGAS Chrome Extension</a></b>.
                            </p>
                          </Fade>
                        </li>
                        <li className="feature-bullets">
                          <Fade left duration={1500}>
                            <p 
                              onMouseEnter={(e) => setDeskGraphic(infoGraphic2c)}
                              onMouseOut={(e) => setDeskGraphic(infoGraphic2)}
                            >
                              Get a <b>headstart</b> with our Code Templates which are built and <i><b>optimized</b></i> for Google Apps Script.
                            </p>
                          </Fade>
                        </li>
                      </ul>
                    </div>
                    <div className="column">
                    <figure 
                        className="image"
                        style={{
                          marginBottom: "-150px",
                          zIndex: "-99"
                        }}
                      >
                        <img src={deskGraphic} />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section className="section section--gradient"
        style={{
          // backgroundColor: "#2E7FEC"
          backgroundImage: `url(${headerbg})`,
          backgroundPosition: `65% 0%`
        }}
      >
        <div className="container">
          <div className="section">
            <div className="columns is-multiline">
              <div className="column is-12">
                <div className="content">
                  <h3 className="has-text-white has-text-weight-semibold is-size-2">
                    Recent Guides
                  </h3>
                  <GuideRoll data={data} page={templateKey}/>
                </div>
                <div className="column is-12 has-text-centered">
                  <Link className="button is-cta" to="/guides">
                    Explore Guides
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
     
     <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns is-multiline">
              <div className="column is-12">
                <div className="content">
                  <div className="columns">
                    <div className="column is-4">
                      <p className="is-size-1-tablet is-size-2-touch">
                        <b>F</b>requently<br />
                        <b>A</b>sked<br />
                        <b>Q</b>uestions
                      </p>
                    </div>
                    <div className="column">
                      <FaqSeciton />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subheading: PropTypes.string,
  data: PropTypes.array,
  templateKey: PropTypes.string
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout>
      <IndexPageTemplate
        templateKey={frontmatter.templateKey}
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
        data={posts}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    })
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: {order: DESC, fields: [frontmatter___date]}
      filter: {frontmatter: {templateKey: {eq: "guide-post"}}}
      limit: 3
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            featuredpost
            description
            tags
            featuredimage {
              childImageSharp {
                gatsbyImageData(width: 400, quality: 100, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
    markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
      frontmatter {
        templateKey
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          heading
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
              }
            }
            text
            gumroadurl
          }
          description
        }
      }
    }
  }
`;
