import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby"
import headerimage from "../img/banners/headerimage.svg";
import headerbg from "../img/banners/headerbg.png";

export default function LandingPageHeader(props) {
  const {
    height = 600,
    img,
    imgPosition = "top left",
  } = props;

  return (
    <React.Fragment>
      <div
        className="margin-top-0 has-text-white is-info"
        style={{
          clipPath: "ellipse(80% 67% at 50% 30%)",
          backgroundImage: `url(${headerbg})`,
          backgroundRepeat: 'no-repeat',
          height: "550px",
          color: "#fff"
        }}
      >
        <div className="container">
          <div className="columns is-multiline">
            <div className="column is-two-thirds">
              <section className="hero">
                <div className="hero-body">
                  <p className="subtitle is-size-2-tablet is-size-3-touch">
                    <strong className="title is-size-1-tablet is-size-2-touch">Automate </strong>
                    your workflows like a
                    <b className="title is-size-1-tablet is-size-2-touch"><i> Pro</i></b>
                    <i className="subtitle is-size-2-tablet is-size-3-touch"> <u>without hiring one</u></i>
                  </p>
                  <p className="is-size-4-tablet is-size-5-touch">
                    <b className="title is-size-4-tablet is-size-5-touch"><i>Supercharge</i></b> your Google Apps Script builds with NitroGAS
                  </p>
                  <a className="button is-cta is-size-5-tablet is-size-6-touch" href="https://chrome.google.com/webstore/detail/bootstrapping-tools-nitro/najbgpipgacnhjcaabbhdaddlmebmopb" target="_blank" rel="nofollow">
                    Get the NitroGAS Chrome Extension
                  </a>
                </div>
              </section>
            </div>
            <div className="column is-hidden-touch">
              <img
                src={headerimage}
                alt="Automation Image"
                style={{ marginTop: "10%" ,width: "500px", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

LandingPageHeader.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  height: PropTypes.number,
};
