import React from "react"
// import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion'
import { Link } from "gatsby"
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export default function FaqSection() {
    const items = [
        {
            question: `Does it matter if I’m not bootstrapping my own business?`,
            answer: `Our content is made for anyone looking to enhance their abilities by learning to code. Whether that’s so you can operate your business better or just to do your job better, everything you’ll learn with us will tip the scales of success in your favor.`      
        },
        {
            question: `Do I need to have some coding experience?`,
            answer: `Having some coding experience will help, but it’s not necessary at all. We have a collection of beginner-friendly guides that’ll help you go from zero to hero in no time. By the time you’re going through the more complex guides, you won’t recognize yourself.`      
        },
        {
            question: `How much does it cost?`,
            answer: `We're here to help you utilize Google Apps Scripts more effectively, so all of the guides, code snippets, code generation tools, and also the NitroGAS Chrome Extension are 100% FREE. We also have Code Templates for purchase, made specifically for use in Google Apps Script.`
        },
        {
            question: `Can I hire Bootstrapping Tools to build my solution?`,
            answer: `Absolutely! Bootstrapping Tools is a one-person shop though, so the amount of projects I can take on is limited. Fill out an inquiry form and I'll reach back out as soon as I can.`,
            link: '/hire-us'
        },
    ]
    return (
    <React.Fragment>
        {items.map((item) => 
        <Accordion 
            className="accordion"
            TransitionProps={{ unmountOnExit: true }}
        >
            <AccordionSummary>
                <Typography className="accordion__button">
                    {item.question}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion__panel">
                <p>{item.answer}</p>
                {item.link && 
                    <Link to={item.link}>
                        <button className="button is-cta is-cta-secondary">
                            Inquiry Form
                        </button>
                    </Link>
                }
            </AccordionDetails>
        </Accordion>
        )}
    </React.Fragment>
  );
}